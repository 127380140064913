interface KeyValuePair {
  [key: string]: any
}

const config: KeyValuePair = {}

export function loadConfig() {
  return fetch('/config.json')
    .then((result) => result.json() as Promise<KeyValuePair>)
    .then((newConfig) => {
      for (const prop in config) {
        delete config[prop]
      }
      for (const prop in newConfig) {
        config[prop] = newConfig[prop]
      }
      return config
    })
    .then((config) => {
      for (const c in config) {
        if (config.hasOwnProperty(c)) {
          // Set configs available through Window object so that remotes can be loaded at runtime
          ;(window as KeyValuePair)[c] = config[c]
        }
      }
    })
    .catch((error) => {
      console.log('Error loading runtime config. ', error)
    })
}

export default config
